import React, { useState } from 'react'
import styles from './accordion.module.css'

export const Accordion = ({ title, marker, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`${styles.accordion} ${isOpen ? styles.accordionOpened : ''}`}>
      <div onClick={() => setIsOpen(!isOpen)} className={`${styles.accordionLabel}`}>
        <div className={styles.accordionMarker}>{marker}</div>
        <div className={styles.accordionTitle}>{title}</div>
        <div className={styles.viewMoreButtonPlus} />
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  )
}
